import {
  Body,
  Box,
  Button,
  buttonLink,
  CheckboxField,
  Dialog,
  ErrorBanner,
  FormRow,
  FormSection,
  Heading,
  link,
  Stack,
  unsafeLocalizedString,
  useForm,
  useIsMobileLayout,
  validators,
} from "design-system";
import { boolean, option, taskEither } from "fp-ts";
import {
  constFalse,
  constNull,
  constVoid,
  identity,
  pipe,
} from "fp-ts/function";
import {
  foldTenant,
  MoneyAmount,
  unsafeNonNegativeInteger,
} from "../../../globalDomain";
import { useFormatMessage, useFormatMoneyAmount } from "../../../intl";
import * as api from "../api";
import { CoverageCard } from "./CoverageCard";
import { BackButton } from "../../../Common/BackButton/BackButton";
import { useAppContext } from "../../../useAppContext";
import { IO } from "fp-ts/IO";
import { Option } from "fp-ts/Option";
import * as classes from "./InsuranceChoose.treat";
import cx from "classnames";
import { useCommand } from "../../../useAPI";
import { useState, useRef, useEffect } from "react";
import { FilePDFDialog } from "../../../Common/Dialogs/FilePDFDialog/FilePDFDialog";
import { YesNoRadioGroupField } from "../../../Common/YesNoRadioGroup/YesNoRadioGroupField";
import { TaskEither } from "fp-ts/TaskEither";
import { palette } from "design-system/lib/styleConstants";
import { InfoTooltip } from "../../../Common/InfoTooltip/InfoTooltip";

export interface CPIAdditionalQuestions {
  consideringFinancial: boolean;
  declared: boolean;
  experienceWithCPI: Option<boolean>;
  knowledge: Option<boolean>;
  options: boolean;
}

type Props = {
  onBack: (additionalAnswers: Option<CPIAdditionalQuestions>) => unknown;
  onInsuranceChosen: (
    chosenInsuranceType: api.CreditProtectionInsuranceType,
    additionalAnswers: Option<CPIAdditionalQuestions>
  ) => TaskEither<unknown, unknown>;
  onDismiss: (
    additionalAnswers: Option<CPIAdditionalQuestions>
  ) => TaskEither<unknown, unknown>;
  didAnswerSolvencyQuestions: boolean;
  cpiPackageList: api.CPIPackageList;
  currentInsuranceType: api.CreditProtectionInsuranceType;
  currentOfferInstallment: MoneyAmount;
  onReviewSolvencyQuestions: IO<unknown>;
  additionalAnswers: Option<CPIAdditionalQuestions>;
  solvencyAnswered: boolean;
  noSelection: boolean;
  onNoCPIWanted: () => unknown;
};

export function InsuranceChoose(props: Props) {
  const formatMessage = useFormatMessage();
  const isMobileLayout = useIsMobileLayout();
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const insuranceDocument = useCommand(api.insuranceDocument);
  const setAdditionalQuestions = useCommand(api.setAdditionalQuestions);
  const [hasFormError, setHasFormError] = useState(false);

  const currentAdditionalAnswers = useRef<Option<CPIAdditionalQuestions>>(
    option.none
  );

  const initialValues = pipe(
    props.additionalAnswers,
    option.fold(
      () => ({
        options: false,
        consideringFinancial: option.none,
        declared: false,
        experienceWithCPI: option.some(false),
        knowledge: option.some(false),
      }),
      additionalAnswers => ({
        ...additionalAnswers,
        consideringFinancial: option.some(
          additionalAnswers.consideringFinancial
        ),
      })
    )
  );

  const wipAdditionalAnswers = useRef<CPIAdditionalQuestions>({
    ...initialValues,
    consideringFinancial: pipe(
      initialValues.consideringFinancial,
      option.getOrElse(constFalse)
    ),
  });

  const {
    apiParameters: { tenant },
  } = useAppContext();

  const { fieldProps, handleSubmit, values } = useForm(
    {
      initialValues,
      fieldValidators: () =>
        foldTenant(
          tenant,
          () => ({
            declared: validators.checked(
              formatMessage(
                "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.skCheckError"
              )
            ),
            consideringFinancial: validators.inSequence(
              validators.defined<boolean>(
                formatMessage(
                  "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.skConsideringFinancialError"
                )
              ),
              validators.checked(
                formatMessage(
                  "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.skConsideringFinancialError"
                )
              )
            ),
            options: validators.checked(
              formatMessage(
                "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.skCheckError"
              )
            ),
          }),
          () => ({})
        ),
    },
    {
      onSubmit: data =>
        foldTenant(
          tenant,
          () =>
            pipe(
              setAdditionalQuestions({
                declared: data.declared,
                // This is validated when tenant is "SK", so it's a boolean
                consideringFinancial: (data.consideringFinancial as unknown) as boolean,
                experienceWithCPI: pipe(
                  data.experienceWithCPI,
                  option.getOrElse(constFalse)
                ),
                knowledge: pipe(data.knowledge, option.getOrElse(constFalse)),
              }),
              taskEither.chain(() =>
                taskEither.fromIO(() => {
                  currentAdditionalAnswers.current = option.some({
                    ...data,
                    consideringFinancial: (data.consideringFinancial as unknown) as boolean,
                  });
                })
              )
            ),
          () => taskEither.fromIO(constVoid)
        ),
    }
  );

  useEffect(() => {
    wipAdditionalAnswers.current = {
      ...values,
      consideringFinancial: pipe(
        values.consideringFinancial,
        option.getOrElse(constFalse)
      ),
    };
  }, [values]);

  const formatMoneyAmount = useFormatMoneyAmount(
    unsafeNonNegativeInteger(
      foldTenant(
        tenant,
        () => 2,
        () => 0
      )
    )
  );

  function renderCard(insurance: api.CreditProtectionInsurance) {
    const isSelected = insurance.type === props.currentInsuranceType;

    const onSelectInsurance = pipe(
      taskEither.fromIO(() => setHasFormError(false)),
      taskEither.chain(() => handleSubmit),
      taskEither.fold(
        () => taskEither.fromIO(() => setHasFormError(true)),
        () =>
          props.onInsuranceChosen(
            insurance.type,
            currentAdditionalAnswers.current
          )
      )
    );

    const onKeepInsurance = pipe(
      taskEither.fromIO(() => setHasFormError(false)),
      taskEither.chain(() => handleSubmit),
      taskEither.fold(
        () => taskEither.fromIO(() => setHasFormError(true)),
        () =>
          taskEither.fromIO(() =>
            props.onBack(option.some(wipAdditionalAnswers.current))
          )
      )
    );

    return (
      <CoverageCard
        key={insurance.name}
        creditProtectionInsurance={insurance}
        onSelectInsurance={onSelectInsurance}
        onKeepInsurance={
          props.solvencyAnswered ? onSelectInsurance : onKeepInsurance
        }
        selected={isSelected}
        installment={props.currentOfferInstallment}
        disabled={!insurance.eligible}
        isSuggested={false}
        isExpanded={props.solvencyAnswered}
      />
    );
  }

  useEffect(() => {
    pipe(
      document.getElementById("errorBanner"),
      option.fromNullable,
      option.map(e => {
        if (hasFormError) {
          e.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      })
    );
  }, [hasFormError]);

  const onDismiss =
    props.currentInsuranceType === props.cpiPackageList.selectedInsurance
      ? option.some(() =>
          props.onBack(option.some(wipAdditionalAnswers.current))
        )
      : option.some(
          props.onInsuranceChosen(
            props.currentInsuranceType,
            currentAdditionalAnswers.current
          )
        );

  const onBack =
    props.currentInsuranceType === props.cpiPackageList.selectedInsurance
      ? () => props.onBack(option.some(wipAdditionalAnswers.current))
      : props.onInsuranceChosen(
          props.currentInsuranceType,
          currentAdditionalAnswers.current
        );

  const onCancelInsurance = () => {
    if (props.noSelection) {
      props.onNoCPIWanted();
    }
    props.onInsuranceChosen(
      "None",
      option.some(wipAdditionalAnswers.current)
    )();
  };

  return (
    <Dialog
      variant="left"
      title={unsafeLocalizedString("")}
      actions={[]}
      size="large"
      onDismiss={onDismiss}
    >
      <Stack column units={10}>
        <Stack shrink column units={3}>
          {props.noSelection && (
            <Heading size="large" weight="medium" align="left">
              {formatMessage(
                "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.titleHeader"
              )}
            </Heading>
          )}
          <Heading size="small" weight="medium" align="left">
            {formatMessage(
              "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.title"
            )}
          </Heading>
          <Stack shrink column units={3}>
            <Body size="medium" weight="regular">
              {formatMessage(
                "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.paragraph1"
              )}
            </Body>
            <Body size="medium" weight="regular">
              {formatMessage(
                "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.paragraph2",
                {
                  monthlyInstallment: formatMessage(
                    "MoneyAmount",
                    formatMoneyAmount(props.currentOfferInstallment, identity)
                  ),
                }
              )}
            </Body>
            <Box column hAlignContent="left">
              {pipe(
                foldTenant(
                  tenant,
                  () => (
                    <Stack column units={6}>
                      <CheckboxField
                        label={[
                          formatMessage(
                            "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.skCheck"
                          ),
                          buttonLink(
                            () => setShowMoreInfo(true),
                            formatMessage(
                              "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.moreInfoLink"
                            )
                          ),
                        ]}
                        {...fieldProps("declared")}
                        onChange={checked => {
                          fieldProps("declared").onChange(checked);
                          if (checked) {
                            setShowMoreInfo(true);
                          }
                        }}
                      />

                      <FormSection>
                        <FormRow type="full">
                          <YesNoRadioGroupField
                            {...fieldProps("experienceWithCPI")}
                            label={formatMessage(
                              "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.skCPIExperience"
                            )}
                          />
                        </FormRow>
                        <FormRow type="full">
                          <YesNoRadioGroupField
                            {...fieldProps("knowledge")}
                            label={formatMessage(
                              "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.skKnowledge"
                            )}
                          />
                        </FormRow>
                      </FormSection>
                    </Stack>
                  ),
                  () => (
                    <Button
                      variant="text"
                      size="default"
                      label={formatMessage(
                        "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.moreInfoLink"
                      )}
                      action={() => setShowMoreInfo(true)}
                    />
                  )
                )
              )}
            </Box>
            {foldTenant(tenant, constNull, () => (
              <span style={{ position: "relative" }}>
                <Body size="medium" weight="regular" color={palette.neutral700}>
                  {formatMessage(
                    "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.paragraph3.beforeLink"
                  )}
                  {link(
                    formatMessage(
                      "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.paragraph3.link.url"
                    ),
                    formatMessage(
                      "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.paragraph3.link"
                    )
                  )}
                  {formatMessage(
                    "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.paragraph3.afterLink"
                  )}
                </Body>
                <InfoTooltip
                  inline
                  content={formatMessage(
                    "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.tooltip"
                  )}
                />
              </span>
            ))}
          </Stack>
        </Stack>
        <Stack grow units={8} height="fit-content" column={isMobileLayout}>
          {props.cpiPackageList.options.map(option => renderCard(option))}
        </Stack>
        {props.didAnswerSolvencyQuestions && (
          <Box hAlignContent="left">
            <Button
              variant="link"
              action={props.onReviewSolvencyQuestions}
              label={formatMessage(
                "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.reviewSolvency"
              )}
            />
          </Box>
        )}
        {pipe(
          hasFormError,
          boolean.fold(constNull, () => (
            <ErrorBanner
              id="errorBanner"
              children={formatMessage(
                "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.requiredFormError"
              )}
            />
          ))
        )}
        {pipe(
          isMobileLayout,
          boolean.fold(
            () => (
              <Stack units={15} hAlignContent="right">
                <BackButton action={onBack} />
                <Button
                  variant="secondary"
                  size="default"
                  action={onCancelInsurance}
                  label={formatMessage(
                    "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.cancelInsurance"
                  )}
                />
              </Stack>
            ),
            () => (
              <Stack units={6} hAlignContent="center" column>
                <Button
                  className={cx(
                    isMobileLayout && classes.cancelInsuranceButtonMobile
                  )}
                  variant="secondary"
                  size="default"
                  action={onCancelInsurance}
                  label={formatMessage(
                    "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.cancelInsuranceMobile"
                  )}
                />
                <BackButton action={onBack} />
              </Stack>
            )
          )
        )}
        {showMoreInfo && (
          <FilePDFDialog
            onDismiss={() => {
              setShowMoreInfo(false);
              fieldProps("declared").onChange(true);
            }}
            title={formatMessage(
              "StandardLoan.CustomerOffer.Document.insuranceAgreement"
            )}
            file={insuranceDocument()}
            downloadUrl={option.none}
          />
        )}
        {pipe(
          foldTenant(
            tenant,
            () => (
              <FormSection>
                <FormRow type="full">
                  <YesNoRadioGroupField
                    {...fieldProps("consideringFinancial")}
                    label={formatMessage(
                      "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.skFinancialScope"
                    )}
                  />
                </FormRow>
                <FormRow type="full">
                  <CheckboxField
                    label={formatMessage(
                      "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.skOptionOne"
                    )}
                    {...fieldProps("options")}
                  />
                </FormRow>
                <FormRow type="full">
                  <CheckboxField
                    disabled
                    value={false}
                    name="optionTwo"
                    issues={option.none}
                    onBlur={constVoid}
                    onChange={constVoid}
                    label={formatMessage(
                      "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.skOptionTwo"
                    )}
                  />
                </FormRow>
                <FormRow type="full">
                  <CheckboxField
                    disabled
                    value={false}
                    name="optionThree"
                    issues={option.none}
                    onBlur={constVoid}
                    onChange={constVoid}
                    label={formatMessage(
                      "StandardLoan.CustomerOffer.CPIDialog.InsuranceChoose.skOptionThree"
                    )}
                  />
                </FormRow>
              </FormSection>
            ),
            constNull
          )
        )}
      </Stack>
    </Dialog>
  );
}
