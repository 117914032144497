import {
  foldSmartKeyState,
  SmartKeyMode,
  SmartKeyState,
  SmartKeyVariant,
} from "./SmartKeyState";
import { constNull, pipe } from "fp-ts/function";
import {
  Button,
  InlineLoader,
  Space,
  Stack,
  Box,
  QRCode,
  FileContent,
  LocalizedString,
} from "design-system";

import { useFormatMessage } from "../intl";
import { PushNotificationTimer } from "./PushNotificationTimer";
import { CheckPushAPI, ErrorCode } from "./api";
import { TaskEither } from "fp-ts/TaskEither";
import { Option } from "fp-ts/Option";

type Props = {
  variant: SmartKeyVariant;
  state: SmartKeyState;
  onStart: () => unknown;
  onCancel: () => unknown;
  onTimeout: () => unknown;
  onError: (errorCode: Option<ErrorCode>) => unknown;
  onSuccess: (mode: SmartKeyMode) => unknown;
  checkPushStatus: CheckPushAPI;
  generateQRCode: TaskEither<unknown, void>;
};

function StartButton(props: {
  variant: SmartKeyVariant;
  onClick: () => unknown;
}) {
  const formatMessage = useFormatMessage();
  const label = ((): LocalizedString => {
    switch (props.variant) {
      case "authorization":
        return formatMessage("SmartKey.startButtonLabel.authorization");
      case "authentication":
        return formatMessage("SmartKey.startButtonLabel.authentication");
    }
  })();
  return (
    <Button
      size="default"
      variant="primary"
      label={label}
      action={props.onClick}
    />
  );
}

function OperationInProgressLoader() {
  const formatMessage = useFormatMessage();
  return (
    <InlineLoader
      size="medium"
      message={formatMessage("SmartKey.operationInProgress")}
    />
  );
}

function DisplayQRCode(props: { code: FileContent }) {
  return (
    <Box column vAlignContent="center" hAlignContent="center">
      <QRCode code={props.code} size={180} />
    </Box>
  );
}

export function Right(props: Props) {
  const formatMessage = useFormatMessage();
  return pipe(
    props.state,
    foldSmartKeyState({
      Initial: () => (
        <StartButton variant={props.variant} onClick={props.onStart} />
      ),
      SendingNotification: () => <OperationInProgressLoader />,
      WaitingForNotificationConfirmation: data => (
        <Stack column fluid height="100%">
          <Stack column units={5}>
            <OperationInProgressLoader />
            <PushNotificationTimer
              {...data}
              onExpire={props.onTimeout}
              onError={props.onError}
              onSuccess={() => props.onSuccess("push")}
              checkPushStatus={props.checkPushStatus}
            />
          </Stack>
          <Space units={5} />
          <Button
            size="default"
            variant="secondary"
            action={props.onCancel}
            label={formatMessage("SmartKey.cancelProcess")}
          />
        </Stack>
      ),
      SmartKeyGenericError: () => (
        <StartButton variant={props.variant} onClick={props.onStart} />
      ),
      GeneratingQRCode: () => <OperationInProgressLoader />,
      DisplayingQRCode: s => <DisplayQRCode code={s.QRCode} />,
      KeepDisplayingQRCode: s => <DisplayQRCode code={s.QRCode} />,
      ValidatingQRCode: s => <DisplayQRCode code={s.QRCode} />,
      QRCodePINWrong: s => <DisplayQRCode code={s.QRCode} />,
      KeepQRCodePINWrong: s => <DisplayQRCode code={s.QRCode} />,
      UserBlocked: constNull,
    })
  );
}
