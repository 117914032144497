import { useState, useCallback } from "react";
import {
  Body,
  buttonLink,
  Heading,
  InlineMessage,
  PinInput,
  Space,
  Stack,
  OTP,
  PositiveInteger,
  LocalizedString,
} from "design-system";
import { useFormatMessage } from "../intl";
import { TaskEither } from "fp-ts/TaskEither";
import { SmartKeyVariant } from "./SmartKeyState";

import { Option } from "fp-ts/Option";
import { constFalse, constTrue, constVoid, pipe } from "fp-ts/function";
import { option, taskEither } from "fp-ts";

type PINValidationStatus = "ready" | "invalid" | "noAttemptsLeft";

type Props<L extends PositiveInteger> = {
  pinLength: L;
  onVerifyOTP: (otp: OTP<L>) => TaskEither<unknown, void>;
  onSendPush: () => unknown;
  variant: SmartKeyVariant;
  status: PINValidationStatus;
  transactionId: Option<LocalizedString>;
};

function SendPushLink(props: { onClick: () => unknown }) {
  const formatMessage = useFormatMessage();
  return (
    <Body size="medium" weight="regular">
      {[
        formatMessage("SmartKey.sendPushText"),
        buttonLink(props.onClick, formatMessage("SmartKey.sendPushLink")),
      ]}
    </Body>
  );
}

export function QRCodePINBottom<L extends PositiveInteger>(props: Props<L>) {
  const formatMessage = useFormatMessage();
  const [otp, setOTP] = useState("");
  const [validationError, setValidationError] = useState<
    Option<LocalizedString>
  >(option.none);
  const submitLabel = ((): LocalizedString => {
    switch (props.variant) {
      case "authorization":
        return formatMessage("SmartKey.verifyOTPSubmitLabel.authorization");
      case "authentication":
        return formatMessage("SmartKey.verifyOTPSubmitLabel.authentication");
    }
  })();
  const onSubmit = useCallback(
    (otp: string) =>
      pipe(
        otp,
        OTP(props.pinLength).decode,
        taskEither.fromEither,
        taskEither.mapLeft(constVoid),
        taskEither.chainFirst(() =>
          taskEither.rightIO(() => setValidationError(option.none))
        ),
        taskEither.alt(() =>
          taskEither.leftIO(() =>
            setValidationError(
              option.some(
                formatMessage("Identification.otp.codeTooShort", {
                  length: props.pinLength,
                })
              )
            )
          )
        ),
        taskEither.chain(props.onVerifyOTP)
      ),
    []
  );
  const inlineErrorMessage = pipe(
    validationError,
    option.alt(() =>
      pipe(
        props.status,
        option.fromPredicate(s => s === "invalid"),
        option.map(() => formatMessage("SmartKey.invalidOTPInserted"))
      )
    )
  );
  const displayPINInput = ((): boolean => {
    switch (props.status) {
      case "ready":
      case "invalid":
        return true;
      case "noAttemptsLeft":
        return false;
    }
  })();
  return (
    <Stack fluid width="100%" vAlignContent="top">
      <Stack column units={5}>
        <Heading size="small" weight="regular">
          {formatMessage("SmartKey.qrCodePINTitle")}
        </Heading>
        {displayPINInput && (
          <PinInput
            value={otp}
            onChange={setOTP}
            length={props.pinLength}
            onSubmit={onSubmit}
            submitLabel={submitLabel}
            autoFocus
            hasError={pipe(
              inlineErrorMessage,
              option.fold(constFalse, constTrue)
            )}
            submit="manual"
            issues={option.none}
          />
        )}
        {pipe(
          inlineErrorMessage,
          option.map(error => (
            <>
              <Space units={1} />
              <InlineMessage size="small" type="error" message={error} />
            </>
          )),
          option.toNullable
        )}
        <SendPushLink onClick={props.onSendPush} />
      </Stack>
      {pipe(
        props.transactionId,
        option.map(transactionId => (
          <Body size="medium" weight="regular">
            {formatMessage("SmartKey.transactionId", {
              transactionId,
            })}
          </Body>
        )),
        option.toNullable
      )}
    </Stack>
  );
}
