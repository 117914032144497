import { Body, Box, Loader, Stack, useIsMobileLayout } from "design-system";
import { IO } from "fp-ts/IO";
import { useCommand, usePollingEffect } from "../../useAPI";
import * as api from "../api";
import { getBonitaStatus } from "../api";
import { PersonalDataForm } from "./PersonalDataForm";
import { constant, constNull, constVoid, pipe } from "fp-ts/function";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import { PersonalData, PersonalDataFromOutput } from "../Confirmation/domain";
import { array, boolean, option, taskEither } from "fp-ts";
import { IncomeOutput, IncomeSourceType } from "../IncomeForm/domain";
import { IncomeForm } from "../IncomeForm/IncomeForm";
import { useEffect, useMemo, useRef, useState } from "react";
import { Option } from "fp-ts/Option";
import { ButtonState, DeferredLoadingButton } from "./DeferredLoadingButton";
import { useFormatMessage } from "../../intl";
import * as reworkApi from "../Rework/api";
import {
  ReworkChangesItem,
  ReworkOldValuesOutput,
  StepCommentsOutput,
} from "../Rework/api";
import { ReworkBanner } from "../Rework/ReworkBanner";
import {
  useGetCreditChecksStepChanges,
  useGetPersonalDataChanges,
} from "../Rework/utils";
import { LTVDecision } from "./IncomeAndPersonalData";
import { useRemoteData } from "../../useRemoteData";
import { useAppContext } from "../../useAppContext";
import * as remoteData from "../../RemoteData";
import { palette } from "design-system/lib/styleConstants";
import { useUpdateEffect } from "react-use";

type Props = {
  options: api.PersonalDataOptionsApprovedOutput;
  onNext: (needsAdditionalIncome: boolean) => unknown;
  income: Option<IncomeOutput>;
  reworkData: Option<ReworkOldValuesOutput>;
  personalData: Option<PersonalDataFromOutput>;
  onApplicationRejected: IO<unknown>;
  reworkAll: boolean;
  onLTVStatusError: (ltvDecision: LTVDecision) => unknown;
  hasPreapprovedLimits: boolean;
  onChangeIncomeSourceType: (value: Option<IncomeSourceType>) => void;
};

type State =
  | "normal"
  | "personalDataCollect"
  | "incomeDataCollect"
  | "success"
  | "error";

export function IncomeAndPersonalDataForm(props: Props) {
  const formatMessage = useFormatMessage();

  const isMobileLayout = useIsMobileLayout();

  const getIsCBRejected = useCommand(api.getIsCBRejected);

  const confirmIncomeAndPersonalData = useCommand(
    api.confirmIncomeAndPersonalData
  );

  const [isLoading, setIsLoading] = useState(false);
  const [loadingPoll, setLoadingPoll] = useState(false);
  const [errorNo, setErrorNo] = useState(0);
  const [canPoll, setCanPoll] = useState(false);

  const restartPoll = usePollingEffect(getBonitaStatus, {
    intervalMS: 5000,
    disabled: !canPoll,
    shouldPollingContinue: val => val.bonitaStatus === "PENDING",
    onError: () => setErrorNo(curr => curr + 1),
    onSuccess: response => {
      setErrorNo(0);
      if (response.bonitaStatus === "DONE") {
        setCanPoll(false);
        setLoadingPoll(false);
        if (option.isSome(response.submitExpensesResponse)) {
          props.onNext(
            response.submitExpensesResponse.value.needAdditionalIncomeStep
          );
        }
      }
    },
  });

  useEffect(() => {
    if (errorNo >= 2) {
      // props.onError();
    }
  }, [errorNo]);

  useEffect(() => {
    if (canPoll) {
      restartPoll();
    }
  }, [canPoll]);

  useUpdateEffect(() => {
    if (isLoading) {
      onSubmitButtonClick();
      setIsLoading(false);
    }
  }, [isLoading]);

  const [formState, setFormState] = useState<{
    personalData: Option<PersonalData>;
    state: State;
  }>({ personalData: option.none, state: "normal" });

  const stepComments = useCommand(reworkApi.stepComments);

  const {
    config: { r6Enabled },
  } = useAppContext();

  const comments = useRemoteData(
    useMemo(
      () => stepComments({ reworkCategories: ["BONITA", "PERSONAL_DATA"] }),
      [r6Enabled]
    )
  );

  const onPersonalDataReady: ReaderTaskEither<
    PersonalData,
    unknown,
    unknown
  > = personalData =>
    taskEither.fromIO(() =>
      setFormState({
        personalData: option.some(personalData),
        state: "incomeDataCollect",
      })
    );

  const [incomeSourceType, setIncomeSourceType] = useState("Employed");

  const onDataFailure = () =>
    setFormState({ personalData: option.none, state: "error" });

  const onIncomeDataReady = (values: IncomeOutput) =>
    pipe(
      formState.personalData,
      option.fold(constVoid, personalData =>
        pipe(
          confirmIncomeAndPersonalData({
            income: values,
            personalData,
          }),
          taskEither.mapLeft(onDataFailure),
          taskEither.chain(
            ({
              needAdditionalIncomeStep,
              ltvWithinLimitsStatus,
              isReworkDoPolling,
            }) => {
              if (option.isSome(ltvWithinLimitsStatus)) {
                const status = ltvWithinLimitsStatus.value;
                if (status === "PENDING" || status === "NOK") {
                  return taskEither.fromIO(() => {
                    props.onLTVStatusError(status);
                  });
                } else {
                  return taskEither.fromIO(() => {
                    if (isReworkDoPolling) {
                      setCanPoll(true);
                      setLoadingPoll(true);
                    } else {
                      props.onNext(needAdditionalIncomeStep);
                    }
                  });
                }
              } else {
                return taskEither.fromIO(() => {
                  if (isReworkDoPolling) {
                    setCanPoll(true);
                    setLoadingPoll(true);
                  } else {
                    props.onNext(needAdditionalIncomeStep);
                  }
                });
              }
            }
          )
        )()
      )
    );

  const dispatchWithRejectionCheck = (values: IncomeOutput) => {
    pipe(
      getIsCBRejected(),
      taskEither.chain(({ cbRejected }) => {
        return taskEither.fromIO(() =>
          pipe(
            cbRejected,
            boolean.fold(
              () => onIncomeDataReady(values),
              () => props.onApplicationRejected()
            )
          )
        );
      })
    )();
  };

  const onSubmitButtonClick = () =>
    (formState.state === "normal" || formState.state === "error") &&
    setFormState({
      personalData: option.none,
      state: "personalDataCollect",
    });

  const backToNormalTimeout = 2000;
  const timeoutRef = useRef<number>();

  useEffect(() => {
    formState.state !== "error" &&
      timeoutRef.current != null &&
      window.clearTimeout(timeoutRef.current);
  }, [formState.state]);

  useEffect(
    () =>
      pipe(
        formState.state === "error",
        boolean.fold(
          constVoid,
          () =>
            (timeoutRef.current = window.setTimeout(
              () =>
                setFormState({ personalData: option.none, state: "normal" }),
              backToNormalTimeout
            ))
        )
      ),
    [formState.state]
  );

  const getCreditChecksStepChanges = useGetCreditChecksStepChanges()(
    pipe(
      props.income,
      option.fold(
        () => ({
          allowanceInfo: option.none,
          companyInfo: option.none,
          contractInfo: option.none,
          incomeInfo: option.none,
          uniqueId: option.none,
          hasPreapprovedLimits: props.hasPreapprovedLimits,
        }),
        incomeValue => ({
          allowanceInfo: incomeValue.allowanceInfo,
          companyInfo: incomeValue.companyInfo,
          contractInfo: incomeValue.contractInfo,
          incomeInfo: incomeValue.incomeInfo,
          uniqueId: incomeValue.uniqueId,
          hasPreapprovedLimits: props.hasPreapprovedLimits,
        })
      )
    ),
    pipe(
      props.reworkData,
      option.chain(r => r.incomeAndPersonalData),
      option.chain(r => r.income)
    )
  );
  const getPersonalDataChanges = useGetPersonalDataChanges(
    props.personalData,
    pipe(
      props.reworkData,
      option.chain(r => r.incomeAndPersonalData),
      option.chain(r => r.personalData),
      option.map(r => r.personalData)
    )
  );

  const reworkChanges = pipe(
    [
      pipe(
        getCreditChecksStepChanges,
        option.map(
          array.filter(
            d => d.variant === "item" && option.isSome<any>(d.oldValue)
          )
        ),
        option.getOrElse<ReworkChangesItem[]>(constant([]))
      ),
      pipe(
        getPersonalDataChanges,
        option.getOrElse<ReworkChangesItem[]>(constant([]))
      ),
    ],
    array.flatten
  );

  const reworkComments = pipe(
    comments,
    remoteData.fold(
      () => option.none,
      () => option.none,
      comments => option.some(comments)
    )
  );

  const reworkBanner = (comments: Option<StepCommentsOutput>) => {
    return pipe(
      props.reworkData,
      option.fold(constNull, () => (
        <ReworkBanner
          changes={option.some(reworkChanges)}
          stepComments={comments}
        />
      ))
    );
  };

  const areReworkComments =
    option.isSome(props.reworkData) &&
    option.isSome(reworkComments) &&
    option.isSome(reworkComments.value.stepComments) &&
    reworkComments.value.stepComments.value.length > 0;

  const hasPreapproved =
    (incomeSourceType === "Employed" || incomeSourceType === "CompanyOwner") &&
    (props.options.incomeOptions.hasPreapprovedLimits ||
      props.hasPreapprovedLimits);

  return (
    <Stack column shrink grow units={isMobileLayout ? 6 : 10}>
      <Body
        size={hasPreapproved ? "large" : "medium"}
        weight="regular"
        color={hasPreapproved ? palette.dark : palette.neutral700}
      >
        {hasPreapproved
          ? formatMessage(
              "StandardLoan.CreditChecks.step2.descriptionPreapprovedLimits"
            )
          : formatMessage("StandardLoan.CreditChecks.step2.description")}
      </Body>
      {option.isSome(props.reworkData) &&
        (reworkChanges.length > 0 || areReworkComments) &&
        reworkBanner(reworkComments)}
      <IncomeForm
        canSubmit={formState.state === "incomeDataCollect"}
        incomeData={{
          incomeSourceList: props.options.incomeOptions.sourceOfIncomeOptions,
          specialIncomeSourceList:
            props.options.incomeOptions.specialTypeOfIncomeOptions,
          incomeOptions: props.options.incomeOptions,
          ...pipe(
            props.income,
            option.fold(
              () => ({
                allowanceInfo: option.none,
                companyInfo: option.none,
                contractInfo: option.none,
                incomeInfo: option.none,
                uniqueId: option.none,
                hasPreapprovedLimits: props.hasPreapprovedLimits,
              }),
              incomeValue => ({
                allowanceInfo: incomeValue.allowanceInfo,
                companyInfo: incomeValue.companyInfo,
                contractInfo: incomeValue.contractInfo,
                incomeInfo: incomeValue.incomeInfo,
                uniqueId: incomeValue.uniqueId,
                hasPreapprovedLimits: props.hasPreapprovedLimits,
              })
            )
          ),
        }}
        onDataReady={dispatchWithRejectionCheck}
        onFailure={onDataFailure}
        options={{ isEditing: true, alreadySelectedCompanies: option.none }}
        rework={pipe(
          props.reworkData,
          option.chain(data => data.incomeAndPersonalData)
        )}
        isMainIncome={true}
        reworkAll={props.reworkAll}
        onChangeIncomeSourceType={value => {
          setIncomeSourceType(
            pipe(
              value,
              option.getOrElse(() => "Employed")
            )
          );
          props.onChangeIncomeSourceType(value);
        }}
        isCancelling={false}
        onCancel={constVoid}
      />
      <PersonalDataForm
        canSubmit={formState.state === "personalDataCollect"}
        personalData={pipe(
          props.personalData,
          option.getOrElse(() => props.options.personalDataOptions)
        )}
        onDataReady={onPersonalDataReady}
        onFailure={onDataFailure}
        reworkData={pipe(
          props.reworkData,
          option.chain(reworkData => reworkData.incomeAndPersonalData),
          option.chain(data => data.personalData),
          option.map(data => data.personalData)
        )}
      />
      <Box hAlignContent="right">
        <DeferredLoadingButton
          variant="primary"
          size="default"
          labels={{
            error: formatMessage("Error"),
            loading: formatMessage("Loading"),
            normal: formatMessage("Confirm.buttonLabel"),
            success: formatMessage("Confirm.buttonLabel"),
          }}
          onClick={() => {
            let el = document.querySelector(":focus");

            (el as HTMLElement)?.blur();
            setIsLoading(true);
          }}
          buttonState={buttonState(formState.state)}
        />
      </Box>
      {loadingPoll && (
        <Box hAlignContent="center">
          <Loader />
        </Box>
      )}
    </Stack>
  );
}

function buttonState(formState: State): ButtonState {
  switch (formState) {
    case "error":
      return "error";
    case "normal":
      return "normal";
    case "success":
      return "success";
    case "incomeDataCollect":
    case "personalDataCollect":
      return "loading";
  }
}
