import { LocalizedString, PositiveInteger, FileContent } from "design-system";
import { constant, pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { option } from "fp-ts";
import * as t from "io-ts";
import { option as optionCodec } from "io-ts-types/lib/option";
import { sharedReducerConfig } from "../BranchExperience/useSharedReducer";

const SmartKeyMode = t.keyof({
  push: true,
  qr: true,
});
export type SmartKeyMode = t.TypeOf<typeof SmartKeyMode>;

const SmartKeyVariant = t.keyof({
  authorization: true,
  authentication: true,
});
export type SmartKeyVariant = t.TypeOf<typeof SmartKeyVariant>;

export function foldVariant<R>(
  variant: SmartKeyVariant,
  matches: {
    authorization: () => R;
    authentication: () => R;
  }
): R {
  return matches[variant]();
}

const InitialPushState = t.type({
  type: t.literal("Initial"),
  mode: t.literal("push"),
  canceledOrSuccess: t.boolean,
  transactionId: optionCodec(LocalizedString),
});
type InitialPushState = t.TypeOf<typeof InitialPushState>;

const InitialQRState = t.type({
  type: t.literal("Initial"),
  mode: t.literal("qr"),
});
type InitialQRState = t.TypeOf<typeof InitialQRState>;

export type Initial = InitialPushState | InitialQRState;

const SendingNotification = t.type({ type: t.literal("SendingNotification") });
type SendingNotification = t.TypeOf<typeof SendingNotification>;

const WaitingForNotificationConfirmation = t.type({
  type: t.literal("WaitingForNotificationConfirmation"),
  transactionId: optionCodec(LocalizedString),
  initialRemainingTimeSeconds: PositiveInteger,
});
type WaitingForNotificationConfirmation = t.TypeOf<
  typeof WaitingForNotificationConfirmation
>;

const SmartKeyGenericError = t.type({
  type: t.literal("SmartKeyGenericError"),
  message: LocalizedString,
  mode: SmartKeyMode,
});
type SmartKeyGenericError = t.TypeOf<typeof SmartKeyGenericError>;

const GeneratingQRCode = t.type({
  type: t.literal("GeneratingQRCode"),
});
type GeneratingQRCode = t.TypeOf<typeof GeneratingQRCode>;

const UserBlocked = t.type({
  type: t.literal("UserBlocked"),
  mode: SmartKeyMode,
});
type UserBlocked = t.TypeOf<typeof UserBlocked>;

const DisplayingQRCode = t.type({
  type: t.literal("DisplayingQRCode"),
  QRCode: FileContent,
  transactionId: optionCodec(LocalizedString),
});
type DisplayingQRCode = t.TypeOf<typeof DisplayingQRCode>;

const KeepDisplayingQRCode = t.type({
  type: t.literal("KeepDisplayingQRCode"),
  QRCode: FileContent,
  transactionId: optionCodec(LocalizedString),
});
type KeepDisplayingQRCode = t.TypeOf<typeof KeepDisplayingQRCode>;

const ValidatingQRCode = t.type({
  type: t.literal("ValidatingQRCode"),
  QRCode: FileContent,
  transactionId: optionCodec(LocalizedString),
});
type ValidatingQRCode = t.TypeOf<typeof ValidatingQRCode>;

const QRCodePINWrongErrorType = t.keyof({
  InvalidFormat: true,
  APIMaxAttemptsReached: true,
  APIInvalidPIN: true,
});
const QRCodePINWrong = t.type({
  type: t.literal("QRCodePINWrong"),
  QRCode: FileContent,
  transactionId: optionCodec(LocalizedString),
  errorType: QRCodePINWrongErrorType,
});
type QRCodePINWrong = t.TypeOf<typeof QRCodePINWrong>;

const KeepQRCodePINWrong = t.type({
  type: t.literal("KeepQRCodePINWrong"),
  QRCode: FileContent,
  transactionId: optionCodec(LocalizedString),
  errorType: QRCodePINWrongErrorType,
});
type KeepQRCodePINWrong = t.TypeOf<typeof KeepQRCodePINWrong>;

const SmartKeyState = t.union([
  InitialPushState,
  InitialQRState,
  SendingNotification,
  WaitingForNotificationConfirmation,
  SmartKeyGenericError,
  GeneratingQRCode,
  UserBlocked,
  DisplayingQRCode,
  ValidatingQRCode,
  QRCodePINWrong,
  KeepDisplayingQRCode,
  KeepQRCodePINWrong,
]);
export type SmartKeyState = t.TypeOf<typeof SmartKeyState>;

export function initial(mode: "qr"): SmartKeyState;
export function initial(
  mode: "push",
  transactionId: Option<LocalizedString>,
  canceledOrSuccess: boolean
): SmartKeyState;
export function initial(
  mode: SmartKeyMode,
  transactionId?: Option<LocalizedString>,
  canceledOrSuccess?: boolean
): SmartKeyState {
  switch (mode) {
    case "qr":
      return { type: "Initial", mode: "qr" };
    case "push":
      return {
        type: "Initial",
        mode: "push",
        canceledOrSuccess: canceledOrSuccess!,
        transactionId: transactionId!,
      };
  }
}

const sendingNotification: SmartKeyState = {
  type: "SendingNotification",
};

function waitingForNotificationConfirmation(
  payload: Omit<WaitingForNotificationConfirmation, "type">
): SmartKeyState {
  return { ...payload, type: "WaitingForNotificationConfirmation" };
}

function smartKeyGenericError(
  payload: Omit<SmartKeyGenericError, "type">
): SmartKeyState {
  return { ...payload, type: "SmartKeyGenericError" };
}

const generatingQRCode: SmartKeyState = { type: "GeneratingQRCode" };

function userBlocked(payload: Omit<UserBlocked, "type">): SmartKeyState {
  return { ...payload, type: "UserBlocked" };
}

function displayingQRCode(
  payload: Omit<DisplayingQRCode, "type">
): SmartKeyState {
  return { ...payload, type: "DisplayingQRCode" };
}

function validatingQRCode(
  payload: Omit<ValidatingQRCode, "type">
): SmartKeyState {
  return { ...payload, type: "ValidatingQRCode" };
}

function qrCodePINWrong(payload: Omit<QRCodePINWrong, "type">): SmartKeyState {
  return { ...payload, type: "QRCodePINWrong" };
}

export function foldSmartKeyState<R>(
  matches: {
    [K in SmartKeyState["type"]]: (
      state: Omit<Extract<SmartKeyState, { type: K }>, "type">
    ) => R;
  }
): (state: SmartKeyState) => R {
  return state => matches[state.type](state as any);
}

export function modeFromState(state: SmartKeyState): SmartKeyMode {
  return pipe(
    state,
    foldSmartKeyState({
      Initial: i => i.mode,
      SendingNotification: constant("push"),
      WaitingForNotificationConfirmation: constant("push"),
      SmartKeyGenericError: e => e.mode,
      GeneratingQRCode: constant("qr"),
      DisplayingQRCode: constant("qr"),
      KeepDisplayingQRCode: constant("qr"),
      ValidatingQRCode: constant("qr"),
      QRCodePINWrong: constant("qr"),
      KeepQRCodePINWrong: constant("qr"),
      UserBlocked: e => e.mode,
    })
  );
}

const SendPushNotificationAction = t.type({
  type: t.literal("SendPushNotificationAction"),
});
type SendPushNotificationAction = t.TypeOf<typeof SendPushNotificationAction>;

const PushNotificationSentAction = t.type({
  type: t.literal("PushNotificationSentAction"),
  transactionId: optionCodec(LocalizedString),
  initialRemainingTimeSeconds: PositiveInteger,
});
type PushNotificationSentAction = t.TypeOf<typeof PushNotificationSentAction>;

const CancelProcessAction = t.type({
  type: t.literal("CancelProcessAction"),
});
type CancelProcessAction = t.TypeOf<typeof CancelProcessAction>;

const SmartKeyErrorAction = t.type({
  type: t.literal("SmartKeyErrorAction"),
  message: LocalizedString,
});
type SmartKeyErrorAction = t.TypeOf<typeof SmartKeyErrorAction>;

const InitialQRCodeAction = t.type({
  type: t.literal("InitialQRCodeAction"),
});
type InitialQRCodeAction = t.TypeOf<typeof InitialQRCodeAction>;

const SwitchToQRCodeAction = t.type({
  type: t.literal("SwitchToQRCodeAction"),
});
type SwitchToQRCodeAction = t.TypeOf<typeof SwitchToQRCodeAction>;

const QRCodeGeneratedAction = t.type({
  type: t.literal("QRCodeGeneratedAction"),
  QRCode: FileContent,
  transactionId: optionCodec(LocalizedString),
});
type QRCodeGeneratedAction = t.TypeOf<typeof QRCodeGeneratedAction>;

const SwitchToPushNotificationAction = t.type({
  type: t.literal("SwitchToPushNotificationAction"),
});
type SwitchToPushNotificationAction = t.TypeOf<
  typeof SwitchToPushNotificationAction
>;

const SubmitQRCodePINAction = t.type({
  type: t.literal("SubmitQRCodePINAction"),
  PIN: t.string,
});
type SubmitQRCodePINAction = t.TypeOf<typeof SubmitQRCodePINAction>;

const QRCodePINErrorAction = t.type({
  type: t.literal("QRCodePINErrorAction"),
  errorType: QRCodePINWrongErrorType,
});
type QRCodePINErrorAction = t.TypeOf<typeof QRCodePINErrorAction>;

const SuccessAction = t.type({
  type: t.literal("SuccessAction"),
});
type SuccessAction = t.TypeOf<typeof SuccessAction>;

const UserBlockedAction = t.type({
  type: t.literal("UserBlockedAction"),
});
type UserBlockedAction = t.TypeOf<typeof UserBlockedAction>;

const SmartKeyAction = t.union([
  SendPushNotificationAction,
  PushNotificationSentAction,
  CancelProcessAction,
  SmartKeyErrorAction,
  InitialQRCodeAction,
  SwitchToQRCodeAction,
  QRCodeGeneratedAction,
  SwitchToPushNotificationAction,
  SubmitQRCodePINAction,
  QRCodePINErrorAction,
  SuccessAction,
  UserBlockedAction,
]);
export type SmartKeyAction = t.TypeOf<typeof SmartKeyAction>;

export const sendPushNotificationAction: SmartKeyAction = {
  type: "SendPushNotificationAction",
};

export function pushNotificationSentAction(
  payload: Omit<PushNotificationSentAction, "type">
): SmartKeyAction {
  return { ...payload, type: "PushNotificationSentAction" };
}

export const initialQRCodeAction: SmartKeyAction = {
  type: "InitialQRCodeAction",
};

export const switchToQRCodeAction: SmartKeyAction = {
  type: "SwitchToQRCodeAction",
};

export const cancelProcessAction: SmartKeyAction = {
  type: "CancelProcessAction",
};

export function smartKeyErrorAction(
  payload: Omit<SmartKeyErrorAction, "type">
): SmartKeyAction {
  return { ...payload, type: "SmartKeyErrorAction" };
}

export function qrCodeGeneratedAction(
  payload: Omit<QRCodeGeneratedAction, "type">
): SmartKeyAction {
  return { ...payload, type: "QRCodeGeneratedAction" };
}

export function submitQRCodePINAction(
  payload: Omit<SubmitQRCodePINAction, "type">
): SmartKeyAction {
  return { ...payload, type: "SubmitQRCodePINAction" };
}

export function qrCodePINErrorAction(
  payload: Omit<QRCodePINErrorAction, "type">
): SmartKeyAction {
  return { ...payload, type: "QRCodePINErrorAction" };
}

export const switchToPushNotificationAction: SmartKeyAction = {
  type: "SwitchToPushNotificationAction",
};

export const successAction: SmartKeyAction = {
  type: "SuccessAction",
};

export const userBlockedAction: SmartKeyAction = {
  type: "UserBlockedAction",
};

function reducer(state: SmartKeyState, action: SmartKeyAction): SmartKeyState {
  switch (action.type) {
    case "SendPushNotificationAction":
      if (
        state.type === "KeepDisplayingQRCode" ||
        state.type === "KeepQRCodePINWrong"
      ) {
        return state;
      }
      return sendingNotification;
    case "PushNotificationSentAction":
      return waitingForNotificationConfirmation(action);
    case "SmartKeyErrorAction":
      return smartKeyGenericError({
        message: action.message,
        mode: modeFromState(state),
      });
    case "InitialQRCodeAction":
      return initial("qr");
    case "SwitchToQRCodeAction":
      return generatingQRCode;
    case "QRCodeGeneratedAction":
      return displayingQRCode({
        QRCode: action.QRCode,
        transactionId: action.transactionId,
      });
    case "SubmitQRCodePINAction":
      return pipe(
        state,
        foldSmartKeyState({
          Initial: constant(state),
          SendingNotification: constant(state),
          WaitingForNotificationConfirmation: constant(state),
          SmartKeyGenericError: constant(state),
          GeneratingQRCode: constant(state),
          DisplayingQRCode: s =>
            validatingQRCode({
              QRCode: s.QRCode,
              transactionId: s.transactionId,
            }),
          KeepDisplayingQRCode: s =>
            validatingQRCode({
              QRCode: s.QRCode,
              transactionId: s.transactionId,
            }),
          ValidatingQRCode: constant(state),
          QRCodePINWrong: s =>
            validatingQRCode({
              QRCode: s.QRCode,
              transactionId: s.transactionId,
            }),
          KeepQRCodePINWrong: s =>
            validatingQRCode({
              QRCode: s.QRCode,
              transactionId: s.transactionId,
            }),
          UserBlocked: constant(state),
        })
      );
    case "QRCodePINErrorAction":
      return pipe(
        state,
        foldSmartKeyState({
          Initial: constant(state),
          SendingNotification: constant(state),
          WaitingForNotificationConfirmation: constant(state),
          SmartKeyGenericError: constant(state),
          GeneratingQRCode: constant(state),
          DisplayingQRCode: constant(state),
          KeepDisplayingQRCode: constant(state),
          ValidatingQRCode: s =>
            qrCodePINWrong({
              QRCode: s.QRCode,
              transactionId: s.transactionId,
              errorType: action.errorType,
            }),
          QRCodePINWrong: constant(state),
          KeepQRCodePINWrong: constant(state),
          UserBlocked: constant(state),
        })
      );
    case "CancelProcessAction":
    case "SuccessAction":
      const mode = modeFromState(state);
      return mode === "qr" ? initial("qr") : initial("push", option.none, true);
    case "SwitchToPushNotificationAction":
      const mode2 = modeFromState(state);
      if (mode2 === "qr") {
        if (
          state.type === "DisplayingQRCode" ||
          state.type === "KeepDisplayingQRCode"
        ) {
          return {
            type: "KeepDisplayingQRCode",
            QRCode: state.QRCode,
            transactionId: state.transactionId,
          };
        }
        if (
          state.type === "QRCodePINWrong" ||
          state.type === "KeepQRCodePINWrong"
        ) {
          return {
            type: "KeepQRCodePINWrong",
            QRCode: state.QRCode,
            transactionId: state.transactionId,
            errorType: state.errorType,
          };
        }
      }
      return initial("push", option.none, false);
    case "UserBlockedAction":
      return userBlocked({ mode: modeFromState(state) });
  }
}

export const reducerConfig = sharedReducerConfig(
  "SmartKey",
  SmartKeyState,
  SmartKeyAction,
  reducer
);
