import { LocalizedString } from "design-system";
import { array, either, nonEmptyArray, option, date, eq } from "fp-ts";
import { Either } from "fp-ts/Either";
import { pipe } from "fp-ts/function";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { Option } from "fp-ts/Option";
import * as t from "io-ts";
import { DateFromISOString } from "io-ts-types/lib/DateFromISOString";
import { nonEmptyArray as nonEmptyArrayCodec } from "io-ts-types/lib/nonEmptyArray";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { RuntimeLocaleKey } from "../../intl";

export interface PersonalDataOption<T> {
  name: Option<RuntimeLocaleKey>;
  value: T;
}
function PersonalDataOption<T extends t.Mixed>(codec: T, name?: string) {
  return t.type(
    {
      name: optionFromNullable(RuntimeLocaleKey),
      value: codec,
    },
    `PersonalDataOption<${name}>`
  );
}

const PersonalDataAutocompleteField = t.keyof({
  CompanyICO: true,
  CompanyName: true,
});

export type PersonalDataAutocompleteField = t.TypeOf<
  typeof PersonalDataAutocompleteField
>;

export interface GenericPersonalDataField<T, O = T> {
  key: RuntimeLocaleKey;
  name: Option<RuntimeLocaleKey>;
  value: T;
  options: Option<NonEmptyArray<PersonalDataOption<O>>>;
}
function GenericPersonalDataField<T extends t.Mixed, O extends t.Mixed = T>(
  codec: T,
  optionsCodec?: O,
  name?: string
) {
  return t.type(
    {
      key: RuntimeLocaleKey,
      name: optionFromNullable(RuntimeLocaleKey),
      value: codec,
      options: optionFromNullable(
        nonEmptyArrayCodec(PersonalDataOption(optionsCodec || codec, name))
      ),
      autocomplete: optionFromNullable(PersonalDataAutocompleteField),
    },
    `GenericPersonalDataField<${name}>`
  );
}

const PersonalDataBooleanField = t.intersection(
  [
    GenericPersonalDataField(t.boolean),
    t.type({
      type: t.literal("boolean"),
    }),
  ],
  "PersonalDataBooleanField"
);
export type PersonalDataBooleanField = t.TypeOf<
  typeof PersonalDataBooleanField
>;

const PersonalDataDateField = t.intersection(
  [
    GenericPersonalDataField(DateFromISOString),
    t.type({
      type: t.literal("date"),
    }),
  ],
  "PersonalDataDateField"
);
export type PersonalDataDateField = t.TypeOf<typeof PersonalDataDateField>;

const PersonalDataNumberField = t.intersection(
  [
    GenericPersonalDataField(optionFromNullable(t.number), t.number),
    t.type({
      type: t.literal("number"),
    }),
  ],
  "PersonalDataNumberField"
);
export type PersonalDataNumberField = t.TypeOf<typeof PersonalDataNumberField>;

const PersonalDataStringField = t.intersection(
  [
    GenericPersonalDataField(LocalizedString),
    t.type({
      type: t.literal("string"),
    }),
  ],
  "PersonalDataStringField"
);
export type PersonalDataStringField = t.TypeOf<typeof PersonalDataStringField>;

export const PersonalDataField = t.union(
  [
    PersonalDataBooleanField,
    PersonalDataDateField,
    PersonalDataNumberField,
    PersonalDataStringField,
  ],
  "PersonalDataField"
);
export type PersonalDataField = t.TypeOf<typeof PersonalDataField>;

export const PersonalData = t.array(PersonalDataField);
export type PersonalData = t.TypeOf<typeof PersonalData>;

export function foldPersonalDataField<T>(
  whenBoolean: (field: PersonalDataBooleanField) => T,
  whenDate: (field: PersonalDataDateField) => T,
  whenNumber: (field: PersonalDataNumberField) => T,
  whenString: (field: PersonalDataStringField) => T,
  whenCompanyICOAutocomplete: (field: PersonalDataStringField) => T,
  whenCompanyNameAutocomplete: (field: PersonalDataStringField) => T
): (field: PersonalDataField) => T {
  return field => {
    switch (field.type) {
      case "boolean":
        return whenBoolean(field);
      case "date":
        return whenDate(field);
      case "number":
        return whenNumber(field);
      case "string":
        return pipe(
          field.autocomplete,
          option.fold(
            () => whenString(field),
            (autocomplete): T => {
              switch (autocomplete) {
                case "CompanyICO":
                  return whenCompanyICOAutocomplete(field);
                case "CompanyName":
                  return whenCompanyNameAutocomplete(field);
              }
            }
          )
        );
    }
  };
}

const PersonalDataBooleanFieldOutput = t.type(
  {
    key: RuntimeLocaleKey,
    value: t.type({
      name: optionFromNullable(RuntimeLocaleKey),
      booleanValue: t.boolean,
      dateTimeValue: t.null,
      decimalValue: t.null,
      stringValue: t.null,
    }),
    autocomplete: optionFromNullable(PersonalDataAutocompleteField),
  },
  "PersonalDataBooleanFieldOutput"
);
type PersonalDataBooleanFieldOutput = t.TypeOf<
  typeof PersonalDataBooleanFieldOutput
>;
const PersonalDataDateFieldValue = t.type(
  {
    name: optionFromNullable(RuntimeLocaleKey),
    dateTimeValue: DateFromISOString,
    booleanValue: t.null,
    decimalValue: t.null,
    stringValue: t.null,
  },
  "PersonalDataDateFieldValue"
);
const PersonalDataDateFieldOutput = t.type(
  {
    key: RuntimeLocaleKey,
    value: PersonalDataDateFieldValue,
    options: optionFromNullable(nonEmptyArrayCodec(PersonalDataDateFieldValue)),
    autocomplete: optionFromNullable(PersonalDataAutocompleteField),
  },
  "PersonalDataDateFieldOutput"
);
type PersonalDataDateFieldOutput = t.TypeOf<typeof PersonalDataDateFieldOutput>;
const PersonalDataNumberFieldValue = t.type(
  {
    name: optionFromNullable(RuntimeLocaleKey),
    decimalValue: optionFromNullable(t.number),
    dateTimeValue: t.null,
    booleanValue: t.null,
    stringValue: t.null,
  },
  "PersonalDataNumberFieldValue"
);
const PersonalDataNumberFieldOption = t.type(
  {
    name: optionFromNullable(RuntimeLocaleKey),
    decimalValue: t.number,
    dateTimeValue: t.null,
    booleanValue: t.null,
    stringValue: t.null,
  },
  "PersonalDataNumberFieldValue"
);
const PersonalDataNumberFieldOutput = t.type(
  {
    key: RuntimeLocaleKey,
    value: PersonalDataNumberFieldValue,
    options: optionFromNullable(
      nonEmptyArrayCodec(PersonalDataNumberFieldOption)
    ),
    autocomplete: optionFromNullable(PersonalDataAutocompleteField),
  },
  "PersonalDataNumberFieldOutput"
);
type PersonalDataNumberFieldOutput = t.TypeOf<
  typeof PersonalDataNumberFieldOutput
>;
const PersonalDataStringFieldValue = t.type(
  {
    name: optionFromNullable(RuntimeLocaleKey),
    stringValue: LocalizedString,
    dateTimeValue: t.null,
    booleanValue: t.null,
    decimalValue: t.null,
  },
  "PersonalDataStringFieldValue"
);
const PersonalDataStringFieldOutput = t.type(
  {
    key: RuntimeLocaleKey,
    value: PersonalDataStringFieldValue,
    options: optionFromNullable(
      nonEmptyArrayCodec(PersonalDataStringFieldValue)
    ),
    autocomplete: optionFromNullable(PersonalDataAutocompleteField),
  },
  "PersonalDataStringFieldOutput"
);
type PersonalDataStringFieldOutput = t.TypeOf<
  typeof PersonalDataStringFieldOutput
>;
const PersonalDataFieldOutput = t.union(
  [
    PersonalDataBooleanFieldOutput,
    PersonalDataDateFieldOutput,
    PersonalDataNumberFieldOutput,
    PersonalDataStringFieldOutput,
  ],
  "PersonalDataFieldOutput"
);
type PersonalDataFieldOutput = t.TypeOf<typeof PersonalDataFieldOutput>;

export const PersonalDataOutput = t.array(
  PersonalDataFieldOutput,
  "PersonalDataOutput"
);
export type PersonalDataOutput = t.TypeOf<typeof PersonalDataOutput>;

function isPersonalDataBooleanFieldOutput(
  field: PersonalDataFieldOutput
): field is PersonalDataBooleanFieldOutput {
  return field.value.booleanValue !== null;
}
function isPersonalDataDateFieldOutput(
  field: PersonalDataFieldOutput
): field is PersonalDataDateFieldOutput {
  return field.value.dateTimeValue !== null;
}
/*
 TODO: this is a hack to avoid writing two codecs, one with non-nullable values (`PersonalDataFromOutput`) and one that uses `Option`s for form fields. If new possible types with optional values were added, all of this would not be enough.
 */
function isPersonalDataNumberFieldOutput(
  field: PersonalDataFieldOutput
): field is PersonalDataNumberFieldOutput {
  return (
    field.value.booleanValue === null &&
    field.value.dateTimeValue === null &&
    field.value.stringValue === null
  );
}
function isPersonalDataStringFieldOutput(
  field: PersonalDataFieldOutput
): field is PersonalDataStringFieldOutput {
  return field.value.stringValue !== null;
}

function decodeValidOption<T, R>(
  options: Array<T>,
  criteria: (el: T) => boolean,
  pluck: (value: T) => R
): R {
  return pipe(
    options,
    array.findFirst(criteria),
    option.getOrElse(() => options[0]),
    pluck
  );
}

export const PersonalDataFromOutput = new t.Type<
  PersonalData,
  PersonalDataOutput,
  unknown
>(
  "PersonalDataFromOutput",
  PersonalData.is,
  (u, c) => {
    const decodeField = (
      field: PersonalDataFieldOutput
    ): Either<t.Errors, PersonalDataField> => {
      if (isPersonalDataBooleanFieldOutput(field)) {
        return t.success({
          type: "boolean",
          key: field.key,
          name: field.value.name,
          value: field.value.booleanValue,
          options: option.none,
          autocomplete: field.autocomplete,
        });
      } else if (isPersonalDataDateFieldOutput(field)) {
        return t.success({
          type: "date",
          key: field.key,
          name: field.value.name,
          value: pipe(
            field.options,
            option.fold(
              () => field.value.dateTimeValue,
              options =>
                decodeValidOption(
                  options,
                  ({ dateTimeValue }) =>
                    date.eqDate.equals(
                      dateTimeValue,
                      field.value.dateTimeValue
                    ),
                  o => o.dateTimeValue
                )
            )
          ),
          options: pipe(
            field.options,
            option.map(
              nonEmptyArray.map(option => ({
                name: option.name,
                value: option.dateTimeValue,
              }))
            )
          ),
          autocomplete: field.autocomplete,
        });
      } else if (isPersonalDataNumberFieldOutput(field)) {
        return t.success({
          type: "number",
          key: field.key,
          name: field.value.name,
          value: pipe(
            field.options,
            option.fold(
              () => field.value.decimalValue,
              options =>
                decodeValidOption(
                  options,
                  ({ decimalValue }) =>
                    option
                      .getEq(eq.eqNumber)
                      .equals(
                        option.some(decimalValue),
                        field.value.decimalValue
                      ),
                  o => option.some(o.decimalValue)
                )
            )
          ),
          options: pipe(
            field.options,
            option.map(
              nonEmptyArray.map(option => ({
                name: option.name,
                value: option.decimalValue,
              }))
            )
          ),
          autocomplete: field.autocomplete,
        });
      } else if (isPersonalDataStringFieldOutput(field)) {
        return t.success({
          type: "string",
          key: field.key,
          name: field.value.name,
          value: pipe(
            field.options,
            option.fold(
              () => field.value.stringValue,
              options =>
                decodeValidOption(
                  options,
                  ({ stringValue }) => stringValue === field.value.stringValue,
                  o => o.stringValue
                )
            )
          ),
          options: pipe(
            field.options,
            option.map(
              nonEmptyArray.map(option => ({
                name: option.name,
                value: option.stringValue,
              }))
            )
          ),
          autocomplete: field.autocomplete,
        });
      } else {
        return t.failure(u, c);
      }
    };

    return pipe(
      PersonalDataOutput.decode(u),
      either.chain(pipe(decodeField, array.traverse(either.either)))
    );
  },
  array.map(
    foldPersonalDataField<PersonalDataFieldOutput>(
      b => ({
        key: b.key,
        value: {
          // @ts-ignore
          name: option.toNullable(b.name),
          booleanValue: b.value,
          dateTimeValue: null,
          decimalValue: null,
          stringValue: null,
        },
      }),
      d => ({
        key: d.key,
        value: {
          name: option.toNullable(d.name),
          booleanValue: null,
          dateTimeValue: d.value,
          decimalValue: null,
          stringValue: null,
        },
        options: null,
      }),
      n => ({
        key: n.key,
        value: {
          name: option.toNullable(n.name),
          booleanValue: null,
          dateTimeValue: null,
          decimalValue: option.toNullable(n.value),
          stringValue: null,
        },
        options: null,
      }),
      s => ({
        key: s.key,
        value: {
          name: option.toNullable(s.name),
          booleanValue: null,
          dateTimeValue: null,
          decimalValue: null,
          stringValue: s.value,
        },
        options: null,
      }),
      s => ({
        key: s.key,
        value: {
          name: option.toNullable(s.name),
          booleanValue: null,
          dateTimeValue: null,
          decimalValue: null,
          stringValue: s.value,
        },
        options: null,
      }),
      s => ({
        key: s.key,
        value: {
          name: option.toNullable(s.name),
          booleanValue: null,
          dateTimeValue: null,
          decimalValue: null,
          stringValue: s.value,
        },
        options: null,
      })
    )
  )
);

export type PersonalDataFromOutput = t.TypeOf<typeof PersonalDataFromOutput>;
