import * as api from "../api";
import { useQuery } from "../../useAPI";
import { Contracts } from "../../Common/PreContractualDocuments/Contracts";
import { constNull, pipe } from "fp-ts/function";
import * as remoteData from "../../RemoteData";
import {
  Action,
  Body,
  Box,
  FeedbackBlock,
  Heading,
  Loader,
  PanelHeaderIcon,
  Stack,
} from "design-system";
import { option } from "fp-ts";
import { useFormatMessage } from "../../intl";
import { Option } from "fp-ts/Option";
import { IO } from "fp-ts/IO";
import { MainContent } from "../../Common/MainContent";

type Props = {
  onGoHp: Option<IO<unknown>>;
};

export function ContractsSent(props: Props) {
  const formatMessage = useFormatMessage();
  const [documents] = useQuery(api.preContractualDocuments);

  return (
    <MainContent>
      <FeedbackBlock
        type="positive"
        size="medium"
        heading={formatMessage("StandardLoan.ContractsSent.title")}
        subheading={option.some(
          formatMessage("StandardLoan.ContractsSent.message")
        )}
        actions={pipe(
          props.onGoHp,
          option.fold<IO<unknown>, [] | [Action]>(
            () => [],
            action => [
              {
                variant: "primary",
                label: formatMessage("StandardLoan.ContractsSent.goToHome"),
                action,
              },
            ]
          )
        )}
      />
      <Stack column units={8}>
        {pipe(
          documents,
          remoteData.fold(
            () => (
              <Box hAlignContent="center">
                <Loader />
              </Box>
            ),
            constNull,
            documents => (
              <Contracts
                readingConfirmationType="none"
                documents={documents}
                showErrors={false}
                isSigningStarted={false}
              />
            )
          )
        )}
        <Stack column units={5}>
          <Heading size="x-small" weight="medium">
            {formatMessage("StandardLoan.ContractsSent.nextSteps")}
          </Heading>
          <Stack units={5} vAlignContent="center">
            <PanelHeaderIcon variant="normal" type="number" value={1} />
            <Body size="medium" weight="regular">
              {formatMessage("StandardLoan.ContractsSent.listItem1")}
            </Body>
          </Stack>
          <Stack units={5} vAlignContent="center">
            <PanelHeaderIcon variant="normal" type="number" value={2} />
            <Body size="medium" weight="regular">
              {formatMessage("StandardLoan.ContractsSent.listItem2")}
            </Body>
          </Stack>
          <Stack units={5} vAlignContent="center">
            <PanelHeaderIcon variant="normal" type="number" value={3} />
            <Body size="medium" weight="regular">
              {formatMessage("StandardLoan.ContractsSent.listItem3")}
            </Body>
          </Stack>
          <Stack units={5} vAlignContent="center">
            <PanelHeaderIcon variant="normal" type="number" value={4} />
            <Body size="medium" weight="regular">
              {formatMessage("StandardLoan.ContractsSent.listItem4")}
            </Body>
          </Stack>
        </Stack>
      </Stack>
    </MainContent>
  );
}
