import { useCallback, useMemo, useEffect, useState, Dispatch } from "react";
import {
  cancelProcessAction,
  foldSmartKeyState,
  foldVariant,
  initial,
  initialQRCodeAction,
  modeFromState,
  pushNotificationSentAction,
  qrCodeGeneratedAction,
  qrCodePINErrorAction,
  sendPushNotificationAction,
  smartKeyErrorAction,
  SmartKeyMode,
  SmartKeyState,
  submitQRCodePINAction,
  successAction,
  switchToPushNotificationAction,
  switchToQRCodeAction,
  userBlockedAction,
  Initial,
  reducerConfig,
  SmartKeyAction,
} from "./SmartKeyState";
import {
  Banner,
  Body,
  buttonLink,
  Card,
  Divider,
  ErrorBanner,
  Space,
  Stack,
  OTP,
  unsafePositiveInteger,
  LocalizedString,
  Loader,
} from "design-system";
import { useFormatMessage } from "../intl";

import { Right } from "./Right";
import * as api from "./api";
import { constNull, pipe } from "fp-ts/function";
import { io, option, taskEither } from "fp-ts";
import { useCommand } from "../useAPI";
import { Left } from "./Left";
import { QRCodePINBottom } from "./QRCodePINBottom";
import { TaskEither } from "fp-ts/TaskEither";
import { Path } from "../APICall";
import { Option } from "fp-ts/Option";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import {
  useChildSharedReducer,
  useParentSharedReducer,
} from "../BranchExperience/useSharedReducer";

export type SmartKeyProps = {
  onSuccess: (method: SmartKeyMode) => unknown;
  onProcessStart: () => unknown;
  processStarted: boolean;
  initialMode?: SmartKeyMode;
} & VariantProps;

export type VariantProps =
  | {
      variant: "authentication";
      preSecurityCheckInfo: api.PreSecurityCheckInput;
      initialTransactionId: Option<LocalizedString>;
    }
  | {
      variant: "authorization";
      authorizeWithPush: ReaderTaskEither<void, unknown, api.SendPushOutput>;
      checkAuthorizationPushPath: Path;
      generateAuthorizationQR: ReaderTaskEither<
        void,
        unknown,
        api.GenerateQRCodeOutput
      >;
      checkAuthorizationQRPath: Path;
      onFailedSignature: () => unknown;
    };

function foldVariantProps<R>(
  variantProps: VariantProps,
  match: {
    [V in VariantProps["variant"]]: (
      props: Omit<Extract<VariantProps, { variant: V }>, "variant">
    ) => R;
  }
): R {
  return match[variantProps.variant](variantProps as any);
}

const pinLength = unsafePositiveInteger(6);

// TODO(gio): this should probably come from API (response to "sendPush") or from a FE config property
const initialRemainingTimeSeconds = unsafePositiveInteger(60);

function GenerateQRLink(props: { onClick: () => unknown }) {
  const formatMessage = useFormatMessage();
  return (
    <Body size="medium" weight="regular">
      {[
        formatMessage("SmartKey.generateQRText"),
        buttonLink(props.onClick, formatMessage("SmartKey.generateQRLink")),
      ]}
    </Body>
  );
}

export function SmartKey(props: SmartKeyProps) {
  const initialState = useMemo((): SmartKeyState => {
    switch (props.initialMode || "push") {
      case "qr":
        return initial("qr");
      case "push":
        return initial(
          "push",
          props.variant === "authentication"
            ? props.initialTransactionId
            : option.none,
          false
        );
    }
  }, []);
  const [state, dispatch] = useParentSharedReducer(reducerConfig, initialState);

  return <SmartKeyInternal {...props} state={state} dispatch={dispatch} />;
}

type SmartKeyInternalProps = SmartKeyProps & {
  state: SmartKeyState;
  dispatch: Dispatch<SmartKeyAction>;
};

export function SmartKeyInternal(props: SmartKeyInternalProps) {
  const formatMessage = useFormatMessage();
  const [resetOTPCount, setResetOTPCount] = useState(0);
  const { state, dispatch } = props;

  const mode = modeFromState(state);

  const sendPushAuthenticationCommand = useCommand(api.preSecurityCheck);

  const sendPushAuthorizationCommand = useMemo(
    () =>
      foldVariantProps(props, {
        authentication: () => () => {
          throw new Error("This should never happen");
        },
        authorization: props => props.authorizeWithPush,
      }),
    []
  );

  const generateAuthenticationQRCode = useCommand(
    api.generateQRCode(["authorization", "smartKey", "generateQR"])
  );

  const generateQRCode = useMemo(
    () =>
      foldVariantProps(props, {
        authentication: () => generateAuthenticationQRCode,
        authorization: props => props.generateAuthorizationQR,
      }),
    []
  );

  const checkQRPIN = useCommand(
    useMemo(
      () =>
        api.checkQRPIN(
          foldVariantProps(props, {
            authentication: () => ["authorization", "smartKey", "checkQR"],
            authorization: props => props.checkAuthorizationQRPath,
          })
        ),
      []
    )
  );
  const checkPushStatusAPI = useMemo(
    () =>
      api.checkPush(
        foldVariantProps(props, {
          authentication: () => [
            "authorization",
            "smartKey",
            "checkSavedAuthorizedSession",
          ],
          authorization: props => props.checkAuthorizationPushPath,
        })
      ),
    []
  );

  const error = useCallback(
    (message: LocalizedString) => () =>
      dispatch(smartKeyErrorAction({ message })),
    []
  );

  const genericSmartKeyError = useCallback(
    error(formatMessage("SmartKey.genericError")),
    []
  );

  const userBlockedSmartKeyError = useCallback(
    error(formatMessage("SmartKey.userBlockedError")),
    []
  );

  const smartKeyExpiredError = useCallback(
    error(formatMessage("SmartKey.expiredError")),
    []
  );

  const errorHandler = useCallback(
    (errorCode: Option<api.ErrorCode>) =>
      pipe(
        errorCode,
        option.fold(
          () => genericSmartKeyError(),
          code => {
            switch (code) {
              case "FAILED_SIGN":
                return props.variant === "authorization"
                  ? props.onFailedSignature()
                  : genericSmartKeyError();
              case "SAVEGATE:801":
                return userBlockedSmartKeyError();
              case "EXPIRED":
                return smartKeyExpiredError();
              case "NOT_FOUND":
                return genericSmartKeyError();
            }
          }
        )
      ),
    []
  );

  const sendPushAuthorization = pipe(
    () => dispatch(sendPushNotificationAction),
    taskEither.fromIO,
    taskEither.chain(sendPushAuthorizationCommand),
    taskEither.chain(({ transactionId }) =>
      taskEither.fromIO(() =>
        dispatch(
          pushNotificationSentAction({
            transactionId: transactionId,
            initialRemainingTimeSeconds,
          })
        )
      )
    ),
    taskEither.alt(() => taskEither.leftIO<unknown, void>(genericSmartKeyError))
  );

  const dispatchPreSecurityCheckResult = (
    result: api.PreSecurityCheckOutput | null
  ) => {
    if (api.PresecurityCheckUserBlockedErrorCodeOutput.is(result)) {
      return dispatch(
        smartKeyErrorAction({
          message: formatMessage("AuthSK.authenticating.userBlockedError"),
        })
      );
    }
    if (api.PresecurityCheckGenericErrorOutput.is(result)) {
      return dispatch(smartKeyErrorAction({ message: result.errorMessage }));
    }

    const transactionId = pipe(
      result,
      option.fromNullable,
      option.chain(o => o.transactionId),
      option.alt(() =>
        pipe(
          state,
          option.fromPredicate((s): s is Initial => s.type === "Initial"),
          option.chain(s => (s.mode === "push" ? s.transactionId : option.none))
        )
      )
    );

    if (
      result === null ||
      result.credentialTypeNeeded === "PUSH_NOTIFICATION"
    ) {
      return dispatch(
        pushNotificationSentAction({
          transactionId,
          initialRemainingTimeSeconds,
        })
      );
    }
  };

  const sendPushAuthentication = pipe(
    () => dispatch(sendPushNotificationAction),
    taskEither.fromIO,
    taskEither.chain(() =>
      // for "authentication",
      // the first push notification is implicitly sent by the preSecurityCheck API call done in the previous (form) step
      // for subsequently requested push notifications, we should send it here
      // In case of error must send preSecurityCheck again
      props.variant === "authentication" &&
      ((state.type === "Initial" &&
        state.mode === "push" &&
        option.isNone(state.transactionId)) ||
        state.type === "KeepDisplayingQRCode" ||
        state.type === "KeepQRCodePINWrong" ||
        (state.type === "SmartKeyGenericError" && state.mode === "push"))
        ? sendPushAuthenticationCommand(props.preSecurityCheckInfo)
        : taskEither.of(null)
    ),
    taskEither.chain(out =>
      taskEither.fromIO(() => dispatchPreSecurityCheckResult(out))
    )
  );
  const sendPushNotification = useCallback(
    () =>
      props.processStarted
        ? foldVariant(props.variant, {
            authorization: sendPushAuthorization,
            authentication: sendPushAuthentication,
          })
        : props.onProcessStart(),
    [props.processStarted, state.type]
  );
  const cancelProcess = useCallback(() => dispatch(cancelProcessAction), []);
  const timeout = useCallback(
    error(formatMessage("SmartKey.timeoutError")),
    []
  );
  const onSuccess = useCallback(
    (mode: SmartKeyMode) =>
      pipe(
        () => props.onSuccess(mode),
        io.chain(() => () => dispatch(successAction))
      )(),
    [props.onSuccess]
  );

  const dispatchQRGenerationResult = (out: api.GenerateQRCodeOutput) => {
    if (
      api.GenerateQRCodeUserBlockedError.is(out) ||
      api.GenerateQRCodeUserBlockedErrorCode.is(out)
    ) {
      return dispatch(userBlockedAction);
    }
    if (api.GenerateQRCodeGenericError.is(out)) {
      return dispatch(smartKeyErrorAction({ message: out.errorMessage }));
    }

    dispatch(
      qrCodeGeneratedAction({
        QRCode: out.QRCode,
        transactionId: out.transactionId,
      })
    );
  };

  const regenerateQRCode = useCallback(
    () =>
      pipe(
        generateQRCode(),
        taskEither.chain(out =>
          taskEither.fromIO(() => {
            dispatchQRGenerationResult(out);
            setResetOTPCount(prevCount => prevCount + 1);
          })
        ),
        taskEither.alt(() =>
          taskEither.leftIO<unknown, void>(genericSmartKeyError)
        )
      ),
    []
  );

  const switchToGenerateQRCode = useCallback(
    () =>
      props.processStarted
        ? pipe(
            () => dispatch(switchToQRCodeAction),
            taskEither.fromIO,
            taskEither.chain(generateQRCode),
            taskEither.chain(out =>
              taskEither.fromIO(() => dispatchQRGenerationResult(out))
            ),
            taskEither.alt(() =>
              taskEither.leftIO<unknown, void>(genericSmartKeyError)
            )
          )()
        : pipe(
            () => dispatch(initialQRCodeAction),
            io.chain(() => props.onProcessStart)
          )(),
    [props.processStarted]
  );
  const switchToSendPush = useCallback(
    () => dispatch(switchToPushNotificationAction),
    []
  );
  const verifyOTP = useCallback(
    (otp: OTP<typeof pinLength>) =>
      pipe(
        () => dispatch(submitQRCodePINAction({ PIN: otp })),
        taskEither.fromIO,
        taskEither.chain(() => checkQRPIN({ pin: otp })),
        taskEither.chain(() => taskEither.fromIO(() => onSuccess("qr"))),
        taskEither.orElse(
          (err): TaskEither<unknown, void> => {
            switch (err.id) {
              case "GenericError":
                return taskEither.leftIO(genericSmartKeyError);
              case "MaxAttemptsReached":
                return taskEither.leftIO(() =>
                  dispatch(
                    qrCodePINErrorAction({
                      errorType: "APIMaxAttemptsReached",
                    })
                  )
                );
              case "InvalidPIN":
                return taskEither.leftIO(() =>
                  dispatch(
                    qrCodePINErrorAction({
                      errorType: "APIInvalidPIN",
                    })
                  )
                );
            }
          }
        )
      ),
    []
  );

  useEffect(() => {
    if (props.processStarted && state.type === "Initial") {
      if (state.mode === "qr") {
        switchToGenerateQRCode();
      } else if (state.mode === "push") {
        sendPushNotification();
      }
    }
  }, [props.processStarted]);

  useEffect(() => {
    // when in authentication mode and from QR we switch to push,
    // the preSecurityCheck+sendPush actions should be triggered sequentially with no user interaction
    // see SBL-15296
    if (
      props.variant === "authentication" &&
      ((state.type === "Initial" &&
        state.mode === "push" &&
        !state.canceledOrSuccess) ||
        state.type === "KeepDisplayingQRCode" ||
        state.type === "KeepQRCodePINWrong")
    ) {
      sendPushAuthentication();
    }
  }, [state]);

  const bottom = pipe(
    state,
    foldSmartKeyState({
      Initial: () => <GenerateQRLink onClick={switchToGenerateQRCode} />,
      SendingNotification: constNull,
      WaitingForNotificationConfirmation: () => (
        <GenerateQRLink onClick={switchToGenerateQRCode} />
      ),
      SmartKeyGenericError: () => (
        <GenerateQRLink onClick={switchToGenerateQRCode} />
      ),
      GeneratingQRCode: constNull,
      DisplayingQRCode: ({ transactionId }) => (
        <>
          <Divider />
          <Space units={5} />
          <QRCodePINBottom
            key={resetOTPCount}
            pinLength={pinLength}
            status="ready"
            onSendPush={switchToSendPush}
            transactionId={transactionId}
            variant={props.variant}
            onVerifyOTP={verifyOTP}
          />
        </>
      ),
      KeepDisplayingQRCode: ({ transactionId }) => (
        <>
          <Divider />
          <Space units={5} />
          <QRCodePINBottom
            key={resetOTPCount}
            pinLength={pinLength}
            status="ready"
            onSendPush={switchToSendPush}
            transactionId={transactionId}
            variant={props.variant}
            onVerifyOTP={verifyOTP}
          />
        </>
      ),
      ValidatingQRCode: ({ transactionId }) => (
        <>
          <Divider />
          <Space units={5} />
          <QRCodePINBottom
            key={resetOTPCount}
            pinLength={pinLength}
            status="ready"
            onSendPush={switchToSendPush}
            transactionId={transactionId}
            variant={props.variant}
            onVerifyOTP={verifyOTP}
          />
        </>
      ),
      QRCodePINWrong: ({ transactionId, errorType }) => (
        <>
          <Divider />
          <Space units={5} />
          <QRCodePINBottom
            key={resetOTPCount}
            pinLength={pinLength}
            status={(() => {
              switch (errorType) {
                case "InvalidFormat":
                case "APIInvalidPIN":
                  return "invalid";
                case "APIMaxAttemptsReached":
                  return "noAttemptsLeft";
              }
            })()}
            onSendPush={switchToSendPush}
            transactionId={transactionId}
            variant={props.variant}
            onVerifyOTP={verifyOTP}
          />
        </>
      ),
      KeepQRCodePINWrong: ({ transactionId, errorType }) => (
        <>
          <Divider />
          <Space units={5} />
          <QRCodePINBottom
            key={resetOTPCount}
            pinLength={pinLength}
            status={(() => {
              switch (errorType) {
                case "InvalidFormat":
                case "APIInvalidPIN":
                  return "invalid";
                case "APIMaxAttemptsReached":
                  return "noAttemptsLeft";
              }
            })()}
            onSendPush={switchToSendPush}
            transactionId={transactionId}
            variant={props.variant}
            onVerifyOTP={verifyOTP}
          />
        </>
      ),
      UserBlocked: constNull,
    })
  );
  const errorBanner = pipe(
    state,
    foldSmartKeyState({
      Initial: constNull,
      SendingNotification: constNull,
      WaitingForNotificationConfirmation: constNull,
      SmartKeyGenericError: ({ message }) => (
        <ErrorBanner>{message}</ErrorBanner>
      ),
      GeneratingQRCode: constNull,
      DisplayingQRCode: constNull,
      KeepDisplayingQRCode: constNull,
      ValidatingQRCode: constNull,
      QRCodePINWrong: ({ errorType }): null | JSX.Element => {
        switch (errorType) {
          case "APIMaxAttemptsReached":
            return (
              <Banner
                type="error"
                title={option.some(
                  formatMessage("SmartKey.maxAttemptsReachedError.title")
                )}
                content={formatMessage(
                  "SmartKey.maxAttemptsReachedError.content"
                )}
                actions={option.some([
                  {
                    variant: "secondary",
                    label: formatMessage(
                      "SmartKey.maxAttemptsReachedError.regenerateLabel"
                    ),
                    action: switchToGenerateQRCode,
                  },
                ])}
              />
            );
          case "APIInvalidPIN":
          case "InvalidFormat":
            return null;
        }
      },
      KeepQRCodePINWrong: ({ errorType }): null | JSX.Element => {
        switch (errorType) {
          case "APIMaxAttemptsReached":
            return (
              <Banner
                type="error"
                title={option.some(
                  formatMessage("SmartKey.maxAttemptsReachedError.title")
                )}
                content={formatMessage(
                  "SmartKey.maxAttemptsReachedError.content"
                )}
                actions={option.some([
                  {
                    variant: "secondary",
                    label: formatMessage(
                      "SmartKey.maxAttemptsReachedError.regenerateLabel"
                    ),
                    action: switchToGenerateQRCode,
                  },
                ])}
              />
            );
          case "APIInvalidPIN":
          case "InvalidFormat":
            return null;
        }
      },
      UserBlocked: () => (
        <Banner
          type="error"
          title={option.some(
            formatMessage("SmartKey.maxAttemptsReachedError.title")
          )}
          content={formatMessage("SmartKey.maxAttemptsReachedError.content")}
          actions={option.some([
            {
              label: formatMessage(
                "SmartKey.maxAttemptsReachedError.regenerateLabel"
              ),
              action: regenerateQRCode(),
              variant: "secondary",
            },
          ])}
        />
      ),
    })
  );

  return (
    <Card>
      <Stack column width="100%" units={5}>
        <Stack vAlignContent="top" fluid>
          <Left mode={mode} variant={props.variant} />
          <Space units={5} />
          <Right
            state={state}
            variant={props.variant}
            onStart={sendPushNotification}
            onCancel={cancelProcess}
            onTimeout={timeout}
            onError={errorHandler}
            onSuccess={onSuccess}
            checkPushStatus={checkPushStatusAPI}
            generateQRCode={regenerateQRCode()}
          />
        </Stack>
        {bottom}
        {errorBanner}
      </Stack>
    </Card>
  );
}

export function SmartKeyChild(props: SmartKeyProps) {
  const [state, dispatch] = useChildSharedReducer(reducerConfig);

  return pipe(
    state,
    option.fold(
      () => <Loader />,
      state => <SmartKeyInternal {...props} state={state} dispatch={dispatch} />
    )
  );
}
