import * as t from "io-ts";
import { apiCall, Path } from "../APICall";
import { eq } from "fp-ts";
import { constFalse } from "fp-ts/function";
import { FileContent, Day, LocalizedString } from "design-system";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { Month, optionFromUndefined } from "../globalDomain";

export const PreSecurityCheckInput = t.type(
  {
    userId: NonEmptyString,
    day: Day,
    month: Month,
  },
  "PreSecurityCheckInput"
);
export type PreSecurityCheckInput = t.TypeOf<typeof PreSecurityCheckInput>;

export const PresecurityCheckUserBlockedErrorCodeOutput = t.type(
  {
    errorCode: t.literal("SAVEGATE:801"),
  },
  "PresecurityCheckUserBlockedErrorCodeOutput"
);
export const PresecurityCheckGenericErrorOutput = t.type(
  {
    errorCode: t.string,
    errorMessage: LocalizedString,
  },
  "PresecurityCheckGenericErrorOutput"
);

export const PreSecurityCheckSuccessOutput = t.type(
  {
    credentialTypeNeeded: t.keyof(
      { QR: true, PUSH_NOTIFICATION: true, OTHER: true },
      "CredentialTypeNeeded"
    ),
    transactionId: optionFromNullable(LocalizedString),
  },
  "PreSecurityCheckSuccessOutput"
);

export type PreSecurityCheckOutput = t.TypeOf<typeof PreSecurityCheckOutput>;

export const PreSecurityCheckOutput = t.union(
  [
    PresecurityCheckUserBlockedErrorCodeOutput,
    PresecurityCheckGenericErrorOutput,
    PreSecurityCheckSuccessOutput,
  ],
  "PreSecurityCheckOutput"
);

export const preSecurityCheck = apiCall({
  inputEq: eq.getStructEq({
    userId: eq.eqString,
    day: eq.eqNumber,
    month: eq.eqNumber,
  }),
  path: ["authorization", "smartKey", "preSecurityCheck"],
  inputCodec: PreSecurityCheckInput,
  outputCodec: PreSecurityCheckOutput,
});

export const SendPushOutput = t.type(
  {
    transactionId: optionFromUndefined(LocalizedString),
  },
  "SendPushOutput"
);
export type SendPushOutput = t.TypeOf<typeof SendPushOutput>;

export const sendPush = (path: Path) =>
  apiCall({
    inputEq: eq.fromEquals(constFalse),
    path,
    inputCodec: t.void,
    outputCodec: SendPushOutput,
  });

const CheckPushNonErrorStatus = t.keyof(
  { RETRY: true, OK: true, CANCEL: true },
  "CheckPushNonErrorStatus"
);

const CheckPushErrorStatus = t.keyof({ ERR: true }, "CheckPushErrorStatus");
export type CheckPushErrorStatus = t.TypeOf<typeof CheckPushErrorStatus>;

const ErrorCode = t.keyof(
  {
    FAILED_SIGN: true,
    "SAVEGATE:801": true,
    EXPIRED: true,
    NOT_FOUND: true,
  },
  "ErrorCode"
);
export type ErrorCode = t.TypeOf<typeof ErrorCode>;

const CheckPushStatus = t.union([
  CheckPushNonErrorStatus,
  CheckPushErrorStatus,
]);
export type CheckPushStatus = t.TypeOf<typeof CheckPushStatus>;

const CheckPushOutput = t.type(
  {
    status: CheckPushStatus,
    errorCode: optionFromUndefined(ErrorCode),
    errorMessage: optionFromUndefined(LocalizedString),
  },
  "CheckPushOutput"
);

export type CheckPushOutput = t.TypeOf<typeof CheckPushOutput>;

export const checkPush = (path: Path) =>
  apiCall({
    inputEq: eq.fromEquals(constFalse),
    path,
    inputCodec: t.void,
    outputCodec: CheckPushOutput,
  });

export type CheckPushAPI = ReturnType<typeof checkPush>;

export const GenerateQRCodeSuccess = t.type(
  {
    QRCode: FileContent,
    transactionId: optionFromNullable(LocalizedString),
  },
  "GenerateQRCodeSuccess"
);
export const GenerateQRCodeUserBlockedError = t.type(
  {
    error: t.literal("UserBlocked"),
  },
  "GenerateQRCodeUserBlockedError"
);
export const GenerateQRCodeUserBlockedErrorCode = t.type(
  {
    errorCode: t.literal("SAVEGATE:801"),
  },
  "GenerateQRCodeUserBlockedErrorCode"
);
export const GenerateQRCodeGenericError = t.type(
  {
    errorCode: t.string,
    errorMessage: LocalizedString,
  },
  "GenerateQRCodeGenericError"
);
export const GenerateQRCodeOutput = t.union(
  [
    GenerateQRCodeSuccess,
    GenerateQRCodeUserBlockedError,
    GenerateQRCodeUserBlockedErrorCode,
    GenerateQRCodeGenericError,
  ],
  "GenerateQRCodeOutput"
);
export type GenerateQRCodeOutput = t.TypeOf<typeof GenerateQRCodeOutput>;

export const generateQRCode = (path: Path) =>
  apiCall({
    inputEq: eq.fromEquals(constFalse),
    path,
    inputCodec: t.void,
    outputCodec: GenerateQRCodeOutput,
  });

const CheckQRPINInput = t.type(
  {
    pin: t.string,
  },
  "CheckQRPINInput"
);

const CheckQRPINError = t.type(
  {
    id: t.keyof({ MaxAttemptsReached: true, InvalidPIN: true }),
  },
  "CheckQRPINError"
);

export const checkQRPIN = (path: Path) =>
  apiCall({
    inputEq: eq.fromEquals(constFalse),
    path,
    inputCodec: CheckQRPINInput,
    errorCodec: CheckQRPINError,
  });

const SendPushNoTransactionOutput = t.type(
  {
    applicationRejected: t.boolean,
  },
  "SendPushNoTransactionOutput"
);

// This version is used in TLS flows because no transactionID is sent in that case
export const sendPushNoTransaction = (path: Path) =>
  apiCall({
    inputEq: eq.fromEquals(constFalse),
    path,
    inputCodec: t.void,
    outputCodec: SendPushNoTransactionOutput,
  });
