import { FlowAccordion, FlowAccordionItem, Space, Stack } from "design-system";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { useEffect, useState } from "react";
import { useFormatMessage } from "../../intl";
import { PageHeading } from "../../Common/PageHeading/PageHeading";
import { CreditChecksContent } from "./CreditChecksContent";
import { Option } from "fp-ts/Option";
import { option, ord, taskEither, boolean } from "fp-ts";
import { LoanOffer } from "../api";
import { IO } from "fp-ts/IO";
import { Reader } from "fp-ts/Reader";
import { StandardLoanFlowType } from "../domain";
import * as api from "../api";
import { IncomeAndPersonalData } from "./IncomeAndPersonalData";
import { constVoid, pipe } from "fp-ts/function";
import { useCommand } from "../../useAPI";
import { Rework } from "../StandardLoanState";
import { MainContent } from "../../Common/MainContent";
import { IncomeSourceType } from "../IncomeForm/domain";

type Props = {
  loanOffer: LoanOffer;
  flowType: StandardLoanFlowType;
  initialConsent: Option<api.StartLoanApplicationInput>;
  onNext: Reader<
    {
      personalDataOptions: api.PersonalDataOptionsApprovedOutput;
      consents: api.StartLoanApplicationInput;
      needsAdditionalIncome?: boolean;
    },
    unknown
  >;
  onApplicationRejected: IO<unknown>;
  onCreditChecksConfirmed: IO<unknown>;
  getIncome: boolean;
  reworkData: Rework;
  onExit: Option<() => unknown>;
  onChangeIncomeSourceType: (value: Option<IncomeSourceType>) => void;
};

const isOptionNumberGt = pipe(ord.ordNumber, option.getOrd, ord.gt);

export function CreditChecks(props: Props) {
  const initCustomer = useCommand(api.initCustomer);

  useEffect(() => {
    pipe(
      ["SmartBanking", "TLSAgent", "HomeBanking"].some(
        flowType => flowType === props.flowType
      ),
      boolean.fold(() => taskEither.right(false), initCustomer),
      taskEither.mapLeft(() => "GenericError")
    )();
  }, []);

  const [activeIndex, setActiveIndex] = useState<Option<number>>(
    option.some(
      option.isSome(props.initialConsent) &&
        option.isNone(props.reworkData.newValues)
        ? 1
        : 0
    )
  );
  const [consents, setConsents] = useState<
    Option<api.StartLoanApplicationInput>
  >(props.initialConsent);

  const setForwardOnlyActiveIndex = (newIndex: Option<number>) => {
    if (isOptionNumberGt(newIndex, activeIndex)) {
      setActiveIndex(newIndex);
    }
  };

  const formatMessage = useFormatMessage();

  const creditChecksStep: FlowAccordionItem = {
    title: formatMessage("StandardLoan.CreditChecks.step1.title"),
    content: ({ down }) => {
      return (
        <CreditChecksContent
          loanOffer={props.loanOffer}
          onNext={consents => {
            setConsents(option.some(consents));
            props.onCreditChecksConfirmed();

            down();
          }}
          flowType={props.flowType}
          initialConsent={props.initialConsent}
        />
      );
    },
  };

  const reviewInformationStep: FlowAccordionItem = {
    title: formatMessage("StandardLoan.CreditChecks.step2.title"),
    content: () => (
      <IncomeAndPersonalData
        onNext={({ personalDataOptions, needsAdditionalIncome }) =>
          pipe(
            consents,
            option.fold(
              () => constVoid,
              consents =>
                props.onNext({
                  personalDataOptions,
                  consents,
                  needsAdditionalIncome,
                })
            )
          )
        }
        onApplicationRejected={props.onApplicationRejected}
        getIncome={props.getIncome}
        reworkData={props.reworkData.oldValues}
        restoredData={pipe(
          props.reworkData.newValues,
          option.map(newValues => newValues.incomeAndPersonalData)
        )}
        restoredPersonalData={pipe(
          props.reworkData.newValues,
          option.chain(r =>
            r.personalDataResponse.status === "OK" &&
            r.personalDataResponse.result === "Approved"
              ? option.some(r.personalDataResponse.personalData)
              : option.none
          )
        )}
        reworkAll={props.reworkData.reworkAll}
        onExit={props.onExit}
        onChangeIncomeSourceType={props.onChangeIncomeSourceType}
      />
    ),
  };

  const items: NonEmptyArray<FlowAccordionItem> = option.isNone(
    props.reworkData.newValues
  )
    ? [creditChecksStep, reviewInformationStep]
    : [reviewInformationStep];

  return (
    <MainContent>
      <Stack column>
        <PageHeading
          title={formatMessage("StandardLoan.CreditChecks.title")}
          description={formatMessage("StandardLoan.CreditChecks.subTitle")}
          hideOnMobile
        />
        <FlowAccordion
          items={items}
          value={activeIndex}
          onChange={setForwardOnlyActiveIndex}
        />
        <Space units={10} />
      </Stack>
    </MainContent>
  );
}
