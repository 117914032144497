import { useParentSharedReducer } from "../BranchExperience/useSharedReducer";
import {
  reducerConfig,
  SmartKeyMode,
  SmartKeyState,
  initial,
  foldSmartKeyState,
} from "./SmartKeyState";
import { option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { pipe, constant } from "fp-ts/function";
import { VariantProps } from "./SmartKey";
import { useMemo } from "react";
import { Box, Card, LocalizedString, ReadOnlyField } from "design-system";
import { useFormatMessage } from "../intl";

type Props = {
  initialMode?: SmartKeyMode;
} & VariantProps;

export function SmartKeyParent(props: Props) {
  const initialState = useMemo((): SmartKeyState => {
    switch (props.initialMode || "push") {
      case "qr":
        return initial("qr");
      case "push":
        return initial(
          "push",
          props.variant === "authentication"
            ? props.initialTransactionId
            : option.none,
          false
        );
    }
  }, []);

  const [state] = useParentSharedReducer(reducerConfig, initialState);

  const formatMessage = useFormatMessage();

  const transactionId = pipe(
    state,
    foldSmartKeyState<Option<LocalizedString>>({
      Initial: () => {
        if (state.type === "Initial") {
          switch (state.mode) {
            case "push":
              return state.transactionId;
            case "qr":
              return option.none;
          }
        }
        return option.none;
      },
      SendingNotification: constant(option.none),
      WaitingForNotificationConfirmation: s => s.transactionId,
      SmartKeyGenericError: constant(option.none),
      GeneratingQRCode: constant(option.none),
      DisplayingQRCode: s => s.transactionId,
      KeepDisplayingQRCode: s => s.transactionId,
      ValidatingQRCode: s => s.transactionId,
      QRCodePINWrong: s => s.transactionId,
      KeepQRCodePINWrong: s => s.transactionId,
      UserBlocked: constant(option.none),
    })
  );

  return (
    <Card>
      <Box column shrink>
        <ReadOnlyField
          size="medium"
          label={formatMessage("OTPAuthorizationParent.transactionId")}
          value={pipe(transactionId, option.getOrElse(constant("")))}
        />
      </Box>
    </Card>
  );
}
